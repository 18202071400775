function Table() {
  return (
    <div className="table-container">
      <h2 className="table-title">Exemple</h2>
      <div className="table-row title-row">
        <div className="table-cell">
          Impôt <span>Société</span>
        </div>
        <div className="table-cell">
          Impôt Revenu <span>Particulier</span>
        </div>
      </div>
      <div className="table-row body-row">
        <div className="table-cell">100€ de don</div>
        <div className="table-cell">100€ de don</div>
      </div>
      <div className="table-row body-row">
        <div className="table-cell">
          <span>60€ de crédit d&apos;impôt</span>
        </div>
        <div className="table-cell">
          <span>66€ de crédit d&apos;impôt</span>
        </div>
      </div>
      <div className="table-row body-row">
        <div className="table-cell">40€ restant</div>
        <div className="table-cell">34€ restant</div>
      </div>
    </div>
  )
}
export default Table
