import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ButtonDon from '../../../components/ButtonDon'
import { scrollToSection } from '../../../utils/scrollToSection'
import menuBurger from '../../../assets/icons/icon_menu_burger.svg'
import Modal from '../../Modal'
function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const links = [
    { name: 'Présentation', link: '#presentation' },
    { name: 'Actions', link: '/#actions' },
    { name: 'Contact', link: '/#contact' }
  ]

  const handlerClick = (sectionId) => {
    if (isOpen) {
      setIsOpen(false)
    }
    setTimeout(() => {
      scrollToSection(sectionId)
    }, 100)
  }

  const itemsNavbar = links.map((link, index) => {
    return (
      <li key={index}>
        <NavLink
          to={link.link}
          aria-current={link.name}
          onClick={() => {
            handlerClick(link.name.toLowerCase())
          }}>
          {link.name}
        </NavLink>
      </li>
    )
  })

  const openMenuModal = () => {
    setIsOpen(true)
  }

  return (
    <nav>
      <img
        src={menuBurger}
        alt={'Menu navigation'}
        onClick={openMenuModal}
        className="menu-burger"
      />
      <Modal isOpen={isOpen} onClose={handlerClick}>
        <ul className="menu-burger-style">
          {itemsNavbar}
          <li>
            <ButtonDon click={handlerClick} />
          </li>
        </ul>
      </Modal>
      <ul className="menu-desktop">
        {itemsNavbar}
        <li>
          <ButtonDon click={handlerClick} />
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
