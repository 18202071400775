import { Link } from 'react-router-dom'
import { handlerClickForScroll } from '../../utils/scrollToSection.js'

function Logo() {
  const nameOfAssociation = 'Anti-Corruption ENQUETE / INVESTIGATION'
  return (
    <Link
      to={'/'}
      onClick={() => {
        handlerClickForScroll('logo')
      }}
      className="logo">
      {nameOfAssociation}
    </Link>
  )
}

export default Logo
