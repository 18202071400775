import Logo from '../../../components/Logo'

function Footer() {
  return (
    <footer className="container background-footer">
      <ul className="wrap" id="footer">
        <li>- Faire un don par chèque</li>
        <li>- Nous écrire par lettre</li>
        <li>
          <h3>Adresse</h3>
          <address>
            <Logo />
            <br />
            11 Avenue des champs verts
            <br />
            67210 OBERNAI
          </address>
        </li>
        <li className="line-gray">
          <h3>Mail</h3>
          <span>
            <a href="mailto:contact@anti-corruption-enquete-investigation.org">
              contact@anti-corruption-enquete-investigation.org
            </a>
          </span>
        </li>
        <li>
          <Logo /> Association à but non lucratif régie par le droit local maintenu en vigueur (
          Bas-Rhin, Haut-Rhin et Moselle ). SIREN 924 707 342.
        </li>
      </ul>
    </footer>
  )
}

export default Footer
