export function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId)
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' })
  }
}

export const handlerClickForScroll = (sectionId) => {
  setTimeout(() => {
    scrollToSection(sectionId)
  }, 100)
}
