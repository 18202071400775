import AppRouter from './Router'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <AppRouter />
    </GoogleReCaptchaProvider>
  )
}

export default App
