import Button from '../../components/Button'
import Logo from '../../components/Logo'
import Slogan from '../../components/Slogan'

function Hero() {
  return (
    <section className="container hero">
      <div className="container-background">
        <div className="container-slogan">
          <h1 className="slogan">
            <Slogan />
          </h1>
          <h2>Association {<Logo />}</h2>
        </div>
      </div>
      <Button />
    </section>
  )
}

export default Hero
