import Logo from '../../../components/Logo'
import Navbar from '../Navbar'

function Header() {
  return (
    <header className="header">
      <p id="logo">
        <Logo />
      </p>
      <Navbar />
    </header>
  )
}

export default Header
