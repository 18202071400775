import React, { useState, useRef } from 'react'
import Modal from '../Modal'
import { useForm, ValidationError } from '@formspree/react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function Contact() {
  const [isOpen, setIsOpen] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [state, handleSubmit, reset] = useForm(process.env.REACT_APP_FORM_KEY, {
    data: { 'g-recaptcha-response': executeRecaptcha }
  })
  const textarea = document.getElementById('g-recaptcha-response-100000')
  if (textarea) {
    textarea.setAttribute('aria-label', 'Captcha response')
  }
  const formRef = useRef()

  const closeModal = () => {
    setIsOpen(false)
  }

  if (state.succeeded) {
    setIsOpen(true)
    reset()
    formRef.current.reset()
  }

  return (
    <section className="container background-green" id="contact">
      <div className="wrap style-one">
        <h2>Nous Contacter</h2>
        <p>Vous pouvez nous contacter en remplissant le formulaire ci-dessous.</p>
        <form ref={formRef} className="container-form" onSubmit={handleSubmit} method="POST">
          <div className="row-form">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Nom Prénom"
              className="style-form"
              required
              aria-label="Nom Prénom"
            />
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className="style-form"
              required
              aria-label="Email"
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </div>
          <div className="custom-selector style-form">
            <select
              name="category"
              id="category"
              className="style-form"
              aria-label="Sélectionner une catégorie">
              <option value="" aria-label="catégorie par défaut">
                - Professionnel / Particulier -
              </option>
              <option value="Avocat" aria-label="Catégorie Avocat">
                Avocat
              </option>
              <option value="Huissier de justice" aria-label="Catégorie Huissier de justice">
                Huissier de justice
              </option>
              <option value="Détective" aria-label="Catégorie Détective">
                Détective
              </option>
              <option value="Particulier" aria-label="Catégorie Particulier">
                Particulier
              </option>
              <option value="Autre" aria-label="Catégorie Autre">
                Autre
              </option>
            </select>
          </div>
          <textarea
            name="message"
            id="message"
            placeholder="Écrivez votre message ici"
            rows="6"
            className="style-form"
            required
            aria-label="Écrivez votre message ici"></textarea>
          <ValidationError prefix="Message" field="message" errors={state.errors} />
          <ul className="container-actions">
            <li>
              <input
                type="submit"
                value="Envoyer"
                className="btn-primary"
                disabled={state.submitting}
              />
            </li>
            <li>
              <input type="reset" value="Effacer" className="btn-primary" />
            </li>
          </ul>
          <Modal isOpen={isOpen} onClose={closeModal}>
            <h2 className="text-modal-style">Message envoyé !</h2>
          </Modal>
        </form>
      </div>
    </section>
  )
}

export default Contact
