function Actions() {
  return (
    <section className="container" id="actions">
      <div className="wrap style-two">
        <h2>Nos actions</h2>
        <p>
          Notre association a une gamme d&apos;actions visant à prévenir et à combattre la
          <span> corruption active-passive, escroquerie, abus de confiance.</span>
        </p>
        <h3>Nous agissons avec des solutions adaptées.</h3>
        <div className="container-article">
          <article>
            <span>
              <i className="icon-rounded fa-solid fa-handshake-angle"></i>
            </span>
            <h3>
              <span>Consulting spécialisé</span>
            </h3>
            <p>
              Notre association peut être contactée pour un consulting spécialisé dans le secteur
              public, privé, immobilier, syndicat de copropriétés et des entreprises en difficulté.
              Nos solutions avec un point de vue pertinent pour identifier les risques et élaborer
              des stratégies.
            </p>
          </article>
          <article>
            <span>
              <i className="icon-rounded fa-solid fa-scale-balanced"></i>
            </span>
            <h3>
              <span>Médiation efficace</span>
            </h3>
            <p>
              Nous proposons des actions de médiations efficaces pour résoudre les conflits de
              manière équitable et rapide. Notre médiation expérimentée facilite des discussions
              ouvertes et constructives pour trouver des solutions mutuellement acceptables
              préservant ainsi les relations et évitant les litiges coûteux.
            </p>
          </article>
          <article>
            <span>
              <i className="icon-rounded fa-solid fa-magnifying-glass"></i>
            </span>
            <h3>
              <span>Enquête approfondie</span>
            </h3>
            <p>
              Notre association mène des enquêtes approfondies quand nous considérons nécessaires et
              adéquats pour découvrir la vérité derrière des cas de suspicion. Notre capacité
              d&apos;analyse est notre savoir-faire sont des atouts majeurs pour recueillir des
              preuves tangibles. Nous sommes déterminés à faire la lumière sur des pratiques
              illégales et que les responsables rendent compte de leurs actes.
            </p>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Actions
