import Table from '../../components/Table'

function Presentation() {
  return (
    <section className="container background-green" id="présentation">
      <div className="wrap style-one">
        <h2>Bienvenue à tous</h2>
        <p>
          Président de l&apos;association à <span>but non lucratif</span> et instigateur de sa
          création, je tiens à remercier tout d&apos;abord les membres, les collaborateurs, tous
          bénévoles.
        </p>
        <p>
          Homme d&apos;expérience, spécialiste du bâtiment, de l&apos;immobilier de A à Z :
          technique, pratique, commercial, oui vraiment tous les aspects.
        </p>
        <p>
          Bien sûr, toutes les autres activités on intervient aussi naturellement et, quand
          nécessaire, nous consultons des spécialistes.
        </p>
        <p>
          De caractère plutôt altruiste avec des principes, quelques valeurs, mais surtout passionné
          d&apos;enquête, d&apos;investigation (énigmes), mes capacités d&apos;observation,
          d&apos;analyse, mon savoir-faire au service de <span>l&apos;intérêt général</span>, voilà
          la force de mon engagement pour combattre les fléaux que sont la corruption
          active-passive, l&apos;escroquerie, l&apos;abus de confiance dans le service public,
          privé, entreprises et dans certains dossiers le concours des professionnels : Avocats,
          Détectives, Huissiers de justice en soutien.
        </p>
        <p>
          En complémentarité <span>Société, Particulier</span>, vos dons donnent droit à
          <span> crédit d&apos;impôt</span>. <br />
          Grâce à votre générosité, on souhaite développer l&apos;association pour rendre la
          structure la plus efficace, la plus redoutable possible.
        </p>
        <Table />
        <h3 className="style-h3">Le Président.</h3>
      </div>
    </section>
  )
}

export default Presentation
