import Hero from '../../containers/Hero'
import React, { useEffect } from 'react'
import Presentation from '../../containers/Presentation'
import Actions from '../../containers/Actions'
import Contact from '../../containers/Contact'
import Support from '../../containers/Support'
import ListInfo from '../../containers/ListInfo'
import { insertJsonIntoHead } from '../../data/insertJsonIntoHead'

function Home() {
  useEffect(() => {
    insertJsonIntoHead()
  }, [])

  return (
    <main>
      <Hero />
      <ListInfo />
      <Presentation />
      <Actions />
      <Contact />
      <Support />
    </main>
  )
}

export default Home
