import React from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import Header from '../../containers/layouts/Header'
import Footer from '../../containers/layouts/Footer'
import Home from '../../pages/Home'
import Error from '../../pages/Error'

function AppRouter() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default AppRouter
