import List from '../../components/Lists'

function ListInfo() {
  return (
    <section className="section-list-info">
      <List />
    </section>
  )
}

export default ListInfo
