import { handlerClickForScroll } from '../../utils/scrollToSection'

function Button() {
  return (
    <button
      className="btn btn-big"
      onClick={() => {
        handlerClickForScroll('actions')
      }}>
      Découvrir nos actions
    </button>
  )
}

export default Button
