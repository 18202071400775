import PropTypes from 'prop-types'

function ButtonDon({ styleBtn }) {
  const handleButtonClick = () => {
    window.open('https://www.payasso.fr/anti-corruption-enquete-investigation/don', '_blank')
  }
  return (
    <div className="btn btn-don" id={styleBtn} onClick={handleButtonClick}>
      Faire un don
    </div>
  )
}

ButtonDon.propTypes = {
  styleBtn: PropTypes.string
}

export default ButtonDon
